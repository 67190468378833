import firebase from "firebase/compat/app";
import { useState, useEffect } from "react";
import { db } from "../firebase";
import { UserInterface, UserRole } from "./../interfaces/User.interface";
import {
  usersCollectionName,
  accountsCollectionName,
  notificationsCollectionName,
} from "../config";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
export default function useFindUser() {
  const [user, setUser] = useState<UserInterface>();
  const [agencyAgent, setAgencyAgent] = useState<UserInterface>();
  const [userAccounts, setUserAccounts] = useState<any[]>([]);
  const [userNotifications, setUserNotifications] = useState<any[]>([]);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const getAllUserWithAccounts = async (id: string) => {
    if (!id) {
      return toast.error("Une erreur c'est produite !");
    }
    const userRef = db.collection(usersCollectionName).doc(id);
    const userSnapshot = await userRef.get();
    const usersData = userSnapshot.data();
    if (usersData) {
      if (usersData?.userRole !== UserRole.ONEAAGENT) {
        firebase.auth().signOut();
        toast.error(
          "Vous n'êtes pas authorisé à accédé à cette console (401)."
        );
        return navigate("/login");
      }
      if (!usersData?.active) {
        firebase.auth().signOut();
        toast.error(
          "Votre compte sur SankMoney a ete temporairement bloque. Merci de contacter le service client"
        );
        return navigate("/login");
      }
      setIsLoading(false);
      setUser(usersData as UserInterface);
    } else {
      firebase.auth().signOut();
      toast.error(
        "Vous n'êtes pas authorisé à accédé à cette console (401)."
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (response) => {
      if (response) {
        getAllUserWithAccounts(response?.uid);
      } else {
        setUser(undefined);
      }
    });

    // Nettoyer l'abonnement lors du démontage
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    user,
    agencyAgent,
    userAccounts,
    userNotifications,
    setUser,
    setAgencyAgent,
    setUserAccounts,
    setUserNotifications,
    isLoading,
    setIsLoading,
  };
}
