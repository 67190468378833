import moment from "moment";
import { useContext, useEffect, useState } from "react";
import {
  IColumn,
  RowRenderFunction,
  IRow,
} from "../../../components/FirestoreTable";
import { Transaction, transactionMap } from "../../../interfaces";
import {
  getTimestamp,
  amountFormater,
  classNames,
  formatTimestamp,
} from "../../../utils";
import Modal from "../../../components/Modal";
import DashboardComponent from "../../../components/DashboardComponents/DashboardActionButtonComponent";
import { UserContext } from "../../../contexts";
import { Table } from "../../../components/CustomTable";
import Moment from "react-moment";
import { retrieveAllInvoicesUrl } from "../../../config";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import * as XLSX from "xlsx";
import toast from "react-hot-toast";

const columns: IColumn[] = [
  {
    field: "createdat",
    label: "Date de création",
  },
  {
    field: "periode",
    label: "Periode",
  },
  {
    field: "id",
    label: "Id de la facture",
  },
  {
    field: "invoicetype",
    label: "Type de facture",
  },
  {
    field: "subscribernumber",
    label: "N abonne",
  },
  {
    field: "subscribername",
    label: "Nom de l'abonne",
  },
  {
    field: "amount",
    label: "Montant",
  },
  {
    field: "transid",
    label: "Id de la transaction",
  },
  {
    field: "subscribercategory",
    label: "Categorie d'abonne",
  },
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdat":
      return (
        <Moment locale="fr" format="DD MMMM YYYY à HH:mm:ss">
          {value}
        </Moment>
      );
    case "id":
      return value ?? "-";
    case "invoicetype":
      return <span>{value}</span>;
    case "subscribernumber":
      return <span>{value}</span>;
    case "subscribername":
      return <span>{value}</span>;
    case "amount":
      return <span>{new Intl.NumberFormat().format(value)} Fcfa</span>;
    case "transid":
      return <span>{value}</span>;
    case "periode":
      return value !== "undefinedundefined"
        ? value.slice(0, 2) + "/" + value.slice(2)
        : "-";
    case "transactionType":
      return <span>{transactionMap.get(value as string)} </span>;
    default:
      return value as string;
  }
};

const ManageOneaInvoicesPage = () => {
  const { user } = useContext(UserContext);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [metterNum, setMetterNum] = useState<string | undefined>(undefined);
  const [invoice, setInvoice] = useState<any>();
  const [invoices, setInvoices] = useState<any>();
  const [invoicesPaid, setInvoicesPaid] = useState<number>(0);
  const [amountInvoicesPaid, setAmountInvoicesPaid] = useState<number>(0);
  const [start, setStart] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, true)
  );
  const [end, setEnd] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, false)
  );
  let refreshDataFn: (() => void) | undefined = undefined;

  const handleInputChange = (event: any) => {
    const { value } = event.target;
    setMetterNum(value ?? undefined);
  };

  const handleChangeStartDate = (value: any) => {
    let startTime = getTimestamp(value.target.value, true);
    setStart(startTime);
  };
  const handleChangeEndDtae = (value: any) => {
    let endTime = getTimestamp(value.target.value, false);
    setEnd(endTime);
  };

  useEffect(() => {
    if (end < start ) {
      toast.error("La date de début doit être inférieur ou égale à la date de fin.");
    }
  }, [end, start]);

  const exportToXLSData = async () => {
    const worksheet = XLSX.utils.json_to_sheet(
      invoices.map((inv: any, index: number) => {
        return {
          "N°": index + 1,
          "Nom/Prénom": inv.subscribername ? inv.subscribername : "-",
          "ID de la transaction": inv.id ? inv.transid : "-",
          "N° Abonné": inv.subscribernumber ? inv.subscribernumber : "-",
          "ID de la facture": inv.id ? inv.id : "-",
          Montant: inv.amount ? inv.amount : "-",
          Période:
            inv.periode !== "undefinedundefined"
              ? inv.periode.slice(0, 2) + "/" + inv.periode.slice(2)
              : "-",
          "Type de transaction": inv.invoicetype ? inv.invoicetype : "-",
          "Date de création": inv.createdat
            ? formatTimestamp(inv.createdat)
            : "-",
          Categorie: inv.subscribercategory ? inv.subscribercategory : "-",
        };
      })
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "export");

    XLSX.writeFile(
      workbook,
      `Détails-comptes-${new Date().toLocaleDateString()}.xlsx`,
      { compression: true }
    );
  };

  return (
    <>
      <div className="px-4 mt-10 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="">
            <h1 className="py-2 text-xl font-semibold text-gray-900">
              Historique des factures
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Vous trouverez ci-dessous toutes les factures ONEA payées via SankMoney.
            </p>
          </div>
          <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200" />
          <DashboardComponent
            retrieveInvoicesPaid={invoicesPaid}
            retrieveAmountInvoices={amountInvoicesPaid}
          />
          <div className="h-8" />
          <div className="flex items-end self-end max-md:flex-col">
            <div className="mt-4 mr-2 sm:mt-0 sm:ml-1 sm:flex-none">
              <div className="my-auto"></div>
            </div>
            <div className="mt-4 mr-2 dark:mr-4 sm:mt-0 sm:ml-1 sm:flex-none">
              <input
                type="search"
                id="search"
                className="block w-full px-3 py-2 pl-10 m-2 text-sm text-gray-900 border border-gray-300 rounded-lg md:mb-0 bg-gray-50 focus:ring-sky-500 focus:border-sky-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-white dark:text-white dark:focus:ring-sky-500 dark:focus:border-sky-500"
                placeholder="N° d'abonné"
                onChange={handleInputChange}
                value={metterNum}
              />
            </div>
            <div className="flex px-1 bg-white dark:bg-gray-800 dark:border-gray-700 dark:rounded-md dark:hover:bg-gray-700 sm:mr-1 lg:mr-1 items-end">
              <div className="mr-1">
                <label
                  htmlFor="dateDebut"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Date début
                </label>
                <input
                  type="date"
                  id="dateDebut"
                  className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-gray-700 bg-white border rounded-md shadow-sm border-sky-300 focus:outline-none focus:ring-1 focus:ring-sky-500 focus:ring-offset-2"
                  defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  onChange={handleChangeStartDate}
                />
              </div>
              <div className="mr-1">
                <label
                  htmlFor="dateFin"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Date fin
                </label>
                <input
                  type="date"
                  id="dateFin"
                  className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-gray-700 bg-white border rounded-md shadow-sm border-sky-300 focus:outline-none focus:ring-1 focus:ring-sky-500 focus:ring-offset-2"
                  defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  onChange={handleChangeEndDtae}
                />
              </div>
              <button
                type="button"
                className={classNames(
                  amountInvoicesPaid && amountInvoicesPaid > 0
                    ? "text-green-700 border-green-300 hover:bg-green-50 focus:ring-green-500"
                    : "text-gray-700 border-gray-300 hover:bg-gray-50 focus:ring-gray-500 cursor-not-allowed",
                  "inline-flex items-center h-10 px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                )}
                onClick={() => exportToXLSData()}
                disabled={invoices && invoices.length > 0 ? false : true}
              >
                <ArrowDownTrayIcon
                  className={classNames(
                    amountInvoicesPaid && amountInvoicesPaid > 0
                      ? "text-green-500"
                      : "text-gray-500",
                    "self-center flex-shrink-0 w-5 h-5 mr-1"
                  )}
                  aria-hidden="true"
                />
                <span className="sr-only">Exporter en XLS</span>
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-col mt-8">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                <Table
                  url={`${retrieveAllInvoicesUrl}`}
                  columns={columns}
                  condensed
                  currentUserId={user?.id ?? undefined}
                  itemcreatedAfter={start}
                  itemcreatedBefore={end}
                  metterNumber={metterNum}
                  rowRender={renderRow}
                  refreshHandler={(refreshData) =>
                    (refreshDataFn = refreshData)
                  }
                  retrieveCurrentPeriodTotalAmount={(amount) =>
                    setAmountInvoicesPaid(amount)
                  }
                  retrieveCurrentPeriodTotalInvoices={(invoiceNum) =>
                    setInvoicesPaid(invoiceNum)
                  }
                  retrieveCurrentPeriodInvoices={(invoices) =>
                    setInvoices(invoices)
                  }
                  loadingSkeletonCount={2}
                  // rowDeleteHandler={onDeleteRow}
                  // rowEditHandler={onEditRow}
                  paginate={{ offset: 0, limit: 100, sort: "createdat:desc" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showDetailsModal}
        maxSize="md"
        closeModal={() => setShowDetailsModal(false)}
      >
        {invoice && (
          <div className="flex justify-center">
            <div className="w-full">
              <div className="m-3 sm:max-w-lg sm:w-full sm:mx-auto">
                <div className="relative flex flex-col bg-white shadow-lg pointer-events-auto rounded-xl dark:bg-gray-800">
                  <div className="p-4 overflow-y-auto sm:p-7">
                    <div className="text-center">
                      <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                        Facture Onea
                      </h3>
                      <p className="overflow-scroll text-sm text-gray-500 ">
                        Facture #
                        {invoice?.oneaInvoiceIds ? (
                          <span className="font-bold">
                            {invoice?.oneaInvoiceIds.join("#")}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>

                    <div className="grid grid-cols-2 gap-5 mt-5 sm:mt-10 sm:grid-cols-2">
                      <div>
                        <span className="block text-xs text-gray-500 uppercase">
                          Nom du client:
                        </span>
                        <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                          {invoice.senderDisplayName}
                        </span>
                      </div>

                      <div>
                        <span className="block text-xs text-gray-500 uppercase">
                          N° du compteur:
                        </span>
                        <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                          {invoice.oneaMeterNumber}
                        </span>
                      </div>
                    </div>

                    <div className="mt-5 sm:mt-10">
                      <h4 className="text-xs font-semibold text-gray-800 uppercase dark:text-gray-200">
                        Facture
                      </h4>
                      <ul className="flex flex-col mt-3">
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Montant</span>
                            <span>{amountFormater(invoice.amount)} Fcfa</span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Penalité</span>
                            <span>
                              {amountFormater(invoice.penality ?? 0)}F
                            </span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span className="mr-1">Periodes</span>
                            <span>{invoice.oneaInvoicePeriodes ?? "-"}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ManageOneaInvoicesPage;
