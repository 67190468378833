/* eslint-disable @typescript-eslint/no-explicit-any */

import { createContext } from "react";
import useFetch from "./useFetch";
import Alert from "../../Alert";
import axios, { AxiosInstance } from "axios";

export const FetchContext = createContext<{ api: AxiosInstance }>({
  api: axios,
});

export interface FetchProps {
  url: string;
  renderData: (data: any) => React.ReactNode;
  renderLoading?: (
    loading: boolean,
    loadingSkeletonCount: number
  ) => React.ReactNode;
  renderError?: (error: any) => React.ReactNode;
  loadingSkeletonUseAvatar?: boolean;
  loadingSkeletonCount?: number;
  refreshHandler?: (refreshData: () => void) => void;
  fetchDataMapper?: (data: any) => any;
  retrieveCurrentPeriodTotalAmount?: (amount: number) => void;
  retrieveCurrentPeriodTotalInvoices?: (invoices: number) => void;
  retrieveCurrentPeriodInvoices?: (invoices: any[]) => void;
}

export function Fetch({
  fetchDataMapper = (data: any) => data,
  ...props
}: FetchProps) {
  const { data, loading, error, refreshData } = useFetch(props.url);
  if (props.retrieveCurrentPeriodTotalAmount && data) {
    props.retrieveCurrentPeriodTotalAmount((data as any).pagination.totalAmount);
  }
  if (props.retrieveCurrentPeriodTotalInvoices && data) {
    props.retrieveCurrentPeriodTotalInvoices((data as any).pagination.total);
  }
  if (props.retrieveCurrentPeriodInvoices && data) {
    props.retrieveCurrentPeriodInvoices((data as any).pagination.invoicesToPrint);
  }
  if (props.refreshHandler) {
    props.refreshHandler(refreshData);
  }
  return (
    <>
      {loading &&
        (!props.renderLoading ? (
          <div className="max-w-5xl p-4 mx-auto">
            {[...Array(props.loadingSkeletonCount || 5)].map((e, i) => (
              <div
                key={`animate-pulse-${i}`}
                className="flex my-1 space-x-4 animate-pulse"
              >
                {props.loadingSkeletonUseAvatar && (
                  <div className="w-12 h-12 bg-gray-400 rounded-full"></div>
                )}
                <div className="flex-1 py-1 space-y-6">
                  <div className="w-3/4 h-4 bg-gray-400 rounded"></div>
                  <div className="space-y-2">
                    <div className="h-4 bg-gray-400 rounded"></div>
                    <div className="w-5/6 h-4 bg-gray-400 rounded"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          props.renderLoading(loading, props.loadingSkeletonCount || 5)
        ))}
      {data && props.renderData(fetchDataMapper(data))}
      {error &&
        (props.renderError ? (
          props.renderError(error)
        ) : (
          <Alert
            type="danger"
            title="Error fetching datas."
            content={
              (error as any).message ||
              "Please retry or contact an administrator."
            }
          />
        ))}
    </>
  );
}

export default Fetch;
