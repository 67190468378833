import { useContext, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Form, FormInput } from "../FormComponents";
import OneaLogoTransparent from "../../ONEA.png";
import SankLogoTransparent from "../../Sank_red.png";
import firebase from "firebase/compat/app";
import Alert from "../Alert";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { usersCollectionName } from "../../config";
import { UserContext } from "../../contexts";
import { UserRole } from "../../interfaces";
import toast from "react-hot-toast";

interface FormFields {
  email: string;
  password: string;
}

const LoginWithEmail = () => {
  const [, setFormReturn] = useState<UseFormReturn<FormFields>>();
  const [error, setError] = useState<any>();
  const navigate = useNavigate();

  const getAllUserWithAccounts = async (id: string) => {
    const userSnapshot = await db.collection(usersCollectionName).doc(id).get();
    const usersData = userSnapshot.data();
    if (usersData?.userRole !== UserRole.ONEAAGENT) {
      firebase.auth().signOut();
      toast.error("Vous n'êtes pas authorisé à accédé à cette console (401).");
      return navigate("/login");
    } else navigate("/");
  };

  const onSubmit = async (data: FormFields) => {
    await firebase
      .auth()
      .signInWithEmailAndPassword(data.email, data.password)
      .then(async (credential) => {
        return getAllUserWithAccounts(credential.user?.uid as string);
      })
      .catch((error) => {
        setError('Email ou mot de passe invalide, veuillez vérifier votre saisie SVP ! ');
      });
  };

  return (
    <div className="m-0 font-sans text-base antialiased font-normal bg-white text-start leading-default text-slate-500">
      <main className="mt-0 transition-all duration-200 ease-soft-in-out">
        <section>
          <div className="relative flex items-center justify-center p-0 mx-auto overflow-hidden bg-center bg-cover min-h-75-screen">
            <div className="container z-10 mt-24">
              <div className="flex flex-wrap mt-0 -mx-3">
                <div className="flex flex-col w-full max-w-full mx-auto border rounded-md border-gradient-to-r from-pink-500 via-red-500 to-yellow-500 md:flex-0 shrink-0 md:w-6/12 lg:w-5/12 xl:w-4/12">
                  <div className="relative flex flex-col min-w-0 mt-10 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                      <div className="flex justify-center">
                        <img
                          className="w-auto h-32 drop-shadow-md"
                          src={OneaLogoTransparent}
                          alt="Onea"
                        />
                        <img
                          className="w-auto h-32 drop-shadow-xl"
                          src={SankLogoTransparent}
                          alt="sank"
                        />
                      </div>
                    </div>
                    <div className="p-4 pb-0 mb-0 bg-transparent border-b-0 rounded-t-2xl">
                      <h3 className="relative z-10 font-bold text-transparent bg-gradient-to-tl from-black to-sky-400 bg-clip-text">
                        Accédez au Portail-Onea
                      </h3>
                      <p className="mb-0">
                        Entrez votre email et votre mot de passe pour vous
                        connecter
                      </p>
                    </div>
                    {error && (
                      <Alert
                        type="danger"
                        title="Authentification à échouer !"
                        content={error}
                      />
                    )}
                    <div className="flex-auto px-4">
                      <Form<FormFields>
                        onSubmit={onSubmit}
                        formReturnAware={setFormReturn}
                        form={{
                          resolver: yupResolver(
                            yup.object().shape({
                              email: yup
                                .string()
                                .email()
                                .required("Le champs est obligatoire"),
                              password: yup
                                .string()
                                .required("Le champs est obligatoire"),
                            })
                          ),
                        }}
                        submitButtonFullWidth
                        submitButtonLabel="Se connecter"
                      >
                        <div className="space-y-4 text-black md:space-y-6">
                          <FormInput
                            name="email"
                            label="Adresse mail"
                            type="email"
                          />
                          <FormInput
                            name="password"
                            label="Mot de passe"
                            type="password"
                          />
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default LoginWithEmail;
