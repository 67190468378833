import { FetchContext } from '.';
import { useState, useEffect, useContext, useCallback } from 'react';

function useFetch(url: string) {
  const { api } = useContext(FetchContext);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const refreshData = useCallback(() => {
    //const source = api.cancelToken.source();
    api
      .get(url)
      //.get(url, { cancelToken: source.token })
      .then((res: any) => {
        setLoading(false);
        //checking for multiple responses for more flexibility
        //with the url we send in.
        res.data && setData(res.data);
      })
      .catch((err: any) => {
        setLoading(false);
        setError(err);
      });
    // return () => {
    //   source.cancel();
    // };
  }, [api, url]);
  useEffect(() => {
    refreshData();
    setLoading(true);
    setData(undefined);
    setError(undefined);
  }, [api, refreshData, url]);

  return { data, loading, error, refreshData };
}

export default useFetch;
