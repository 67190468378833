/* eslint-disable @typescript-eslint/no-empty-interface */
import { Dialog, Transition } from '@headlessui/react';
import { useConfirm } from '../../hooks/useConfirm';
import {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';

/* eslint-disable-next-line */
export interface DestructionConfirmModalState {
  title?: string;
  message?: string;
  destroyBtnLabel?: string;
  cancelBtnLabel?: string;
  isError: boolean;
  isWarning?: boolean;
}

export const InitialConfirmState = {
  title: 'Delete',
  message: `Are you sure you want to ? All of your data will be permanently removed from our servers forever. This action cannot be undone.`,
  destroyBtnLabel: 'Delete',
  cancelBtnLabel: undefined,
  isError: true,
  isWarning: false,
};

export const DestructiveConfirmContext = createContext<{
  show: boolean;
  setShow: any;
  confirmState: DestructionConfirmModalState;
  setConfirmState: any;
}>({
  show: false,
  setShow: null,
  confirmState: InitialConfirmState,
  setConfirmState: null,
});

export interface DestructionConfirmProps {}

export function DestructionConfirmModal(props: DestructionConfirmProps) {
  const cancelButtonRef = useRef(null);
  const ref = useRef<Element | null>();
  const [mounted, setMounted] = useState(false);
  const { onConfirm, onCancel, confirmState } = useConfirm();
  const { show } = useContext(DestructiveConfirmContext);

  const component = (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={onCancel}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Panel className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div
                  className={`flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto ${
                    confirmState.isError ? 'text-red-100' : 'text-yellow-800'
                  }  rounded-full sm:mx-0 sm:h-10 sm:w-10`}
                >
                  <ExclamationCircleIcon
                    className={`w-16 h-16 ${
                      confirmState.isError ? 'text-red-600' : 'text-yellow-600'
                    } `}
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {confirmState.title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {confirmState.message}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                {!confirmState.isWarning && (
                  <button
                    type="button"
                    className={`inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white ${
                      confirmState.isError
                        ? 'bg-red-600 hover:bg-red-700 focus:ring-red-500'
                        : 'bg-yellow-600 hover:bg-yellow-800 focus:ring-yellow-800'
                    } border border-transparent rounded-md shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2  sm:ml-3 sm:w-auto sm:text-sm`}
                    onClick={onConfirm}
                  >
                    {confirmState.destroyBtnLabel}
                  </button>
                )}
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={onCancel}
                  ref={cancelButtonRef}
                >
                  {confirmState.cancelBtnLabel || "Retour"}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
  useEffect(() => {
    ref.current = document.querySelector('#portal');
    setMounted(true);
  }, []);

  return mounted ? createPortal(component, ref.current as Element) : null;
}

export default DestructionConfirmModal;
