// import { UserDto } from '@';

import { createContext } from "react";
import {
  UserInterface,
} from "../interfaces";
import { boolean } from "yup";
export const UserContext = createContext<{
  user?: UserInterface | undefined;
  setUser: any;
 
}>({
  user: undefined,
  setUser: null,
});
