/* eslint-disable jsx-a11y/alt-text */
import { EyeIcon } from "@heroicons/react/24/solid";
import { useContext, useEffect, useState } from "react";

import { UserContext } from "../../contexts";
import { db } from "../../firebase";
import { amountFormater, getTimestamp } from "../../utils";

import { retrieveAllInvoicesUrl, transactionCollectionName } from "../../config";
import HeaderDashboardComponent from "../../components/DashboardComponents/HeaderDashboardComponent";
import { TransactionType, transactionMap } from "../../interfaces";
import moment from "moment";
import Modal from "../../components/Modal";

import { useNavigate } from "react-router-dom";
import {
  IColumn,
  IRow,
  RowRenderFunction,
} from "../../components/FirestoreTable";
import {
  collection,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";
import { Table } from "../../components/CustomTable";
import Moment from "react-moment";

const columns: IColumn[] = [
  {
    field: "createdat",
    label: "Date de création",
  },
  {
    field: "periode",
    label: "Periode",
  },
  {
    field: "id",
    label: "Id de la facture",
  },
  {
    field: "invoicetype",
    label: "Type de facture",
  },
  {
    field: "subscribernumber",
    label: "N abonne",
  },
  {
    field: "subscribername",
    label: "Nom de l'abonne",
  },
  {
    field: "amount",
    label: "Montant",
  },
  {
    field: "transid",
    label: "Id de la transaction",
  },
  {
    field: "subscribercategory",
    label: "Categorie d'abonne",
  },
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdat":
      return (
        <Moment locale="fr" format="DD MMMM YYYY à HH:mm:ss">
          {value}
        </Moment>
      );
    case "id":
      return value ?? "-";
    case "invoicetype":
      return <span>{value}</span>;
    case "subscribernumber":
      return <span>{value}</span>;
    case "subscribername":
      return <span>{value}</span>;
    case "amount":
      return <span>{new Intl.NumberFormat().format(value)} Fcfa</span>;
    case "transid":
      return <span>{value}</span>;
    case "periode":
      return value !== "undefinedundefined"
        ? value.slice(0, 2) + "/" + value.slice(2)
        : "-";
    case "transactionType":
      return <span>{transactionMap.get(value as string)} </span>;
    default:
      return value as string;
  }
};
function Dashboard() {
  const { user } = useContext(UserContext);
  let refreshDataFn: (() => void) | undefined = undefined;
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [seachWithStartDateValue, setSetSeachWithStartDateValue] =
    useState<string>(moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"));
  const [seachWithEndDateValue, setSetSeachWithEndDateValue] = useState<string>(
    moment().format("YYYY-MM-DD HH:mm:ss")
  );
  const [invoice, setInvoice] = useState<any>();
  const [invoicesPaid, setInvoicesPaid] = useState<number>(0);
  const [amountInvoicesPaid, setAmountInvoicesPaid] = useState<number>(0);

  const [startTime, setStartTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, true)
  );
  const [endTime, setEndTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, false)
  );

  const getFilteredByDate = async () => {
    if (seachWithStartDateValue) {
      setStartTime(getTimestamp(seachWithStartDateValue, true));
    }

    if (seachWithEndDateValue) {
      setEndTime(getTimestamp(seachWithEndDateValue, false));
    }
  };

  const getAllInvoices = async () => {
    const count = await getCountFromServer(
      query(
        collection(db, transactionCollectionName),
        where("createdAt", ">=", startTime),
        where("createdAt", "<=", endTime),
        where("transactionType", "==", TransactionType.WATER_BILL_POSTPAID)
      )
    );

    setInvoicesPaid(count.data().count);

    const transactions = await db
      .collection(transactionCollectionName)
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<=", endTime)
      .where("transactionType", "==", TransactionType.WATER_BILL_POSTPAID)
      .get();

    const allAmount = transactions.docs.reduce((acc, current) => {
      const value = parseFloat(current.data().amount);
      return (acc += value);
    }, 0);

    setAmountInvoicesPaid(allAmount);
  };
  const navigate = useNavigate();

  useEffect(() => {
    getFilteredByDate();
    getAllInvoices();
  }, []);

  return (
    <>
      <div className="px-4 py-2 max-md:px-2 ">
        <div className="">
          <div className="">
            <div className="flex max-md:flex-col">
              <div className="">
                <div className="flex justify-center my-4 max-md:flex-col max-md:space-y-4 ">
                  <div className="flex my-auto space-x-3 md:ml-4">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm text-sky-700 border-sky-300 hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                      onClick={() => {
                        navigate("onea/invoices");
                      }}
                    >
                      <EyeIcon
                        className="self-center flex-shrink-0 w-5 h-5 mr-1 text-sky-500"
                        aria-hidden="true"
                      />
                      Voir toutes les factures
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200" />
      <HeaderDashboardComponent
        retrieveInvoicesPaid={invoicesPaid}
        retrieveAmountInvoices={amountInvoicesPaid}
      />

      <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200" />
      <div className="px-4 mt-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="py-2 text-xl font-semibold text-gray-900">
              Les factures.
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Vous trouverez ici toutes les factures payées du jour.
            </p>
          </div>
        </div>
        <div className="flex flex-col mt-8">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                <Table
                  url={`${retrieveAllInvoicesUrl}`}
                  columns={columns}
                  condensed
                  currentUserId={user?.id ?? undefined}
                  itemcreatedAfter={seachWithStartDateValue}
                  itemcreatedBefore={seachWithEndDateValue}
                  rowRender={renderRow}
                  refreshHandler={(refreshData) =>
                    (refreshDataFn = refreshData)
                  }
                  retrieveCurrentPeriodTotalAmount={(amount) =>
                    setAmountInvoicesPaid(amount)
                  }
                  retrieveCurrentPeriodTotalInvoices={(invoiceNum) =>
                    setInvoicesPaid(invoiceNum)
                  }
                  loadingSkeletonCount={2}
                  // rowDeleteHandler={onDeleteRow}
                  // rowEditHandler={onEditRow}
                  paginate={{ offset: 0, limit: 100, sort: "createdat:desc" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showDetailsModal}
        maxSize="md"
        closeModal={() => setShowDetailsModal(false)}
      >
        {invoice && (
          <div className="flex justify-center">
            <div className="w-full">
              <div className="m-3 sm:max-w-lg sm:w-full sm:mx-auto">
                <div className="relative flex flex-col bg-white shadow-lg pointer-events-auto rounded-xl dark:bg-gray-800">
                  <div className="p-4 overflow-y-auto sm:p-7">
                    <div className="text-center">
                      <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                        Facture Onea
                      </h3>
                      <p className="overflow-scroll text-sm text-gray-500 ">
                        Facture #
                        {invoice?.oneaInvoiceIds ? (
                          <span className="font-bold">
                            {invoice?.oneaInvoiceIds.join("#")}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>

                    <div className="grid grid-cols-2 gap-5 mt-5 sm:mt-10 sm:grid-cols-2">
                      <div>
                        <span className="block text-xs text-gray-500 uppercase">
                          Nom du client:
                        </span>
                        <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                          {invoice.senderDisplayName}
                        </span>
                      </div>

                      <div>
                        <span className="block text-xs text-gray-500 uppercase">
                          N° du compteur:
                        </span>
                        <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                          {invoice.oneaMeterNumber}
                        </span>
                      </div>
                    </div>

                    <div className="mt-5 sm:mt-10">
                      <h4 className="text-xs font-semibold text-gray-800 uppercase dark:text-gray-200">
                        Facture
                      </h4>
                      <ul className="flex flex-col mt-3">
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Montant</span>
                            <span>{amountFormater(invoice.amount)} Fcfa</span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Penalité</span>
                            <span>
                              {amountFormater(invoice.penality ?? 0)}F
                            </span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span className="mr-1">Periodes</span>
                            <span>{invoice.oneaInvoicePeriodes ?? "-"}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}

export default Dashboard;
