
import {
  ArrowPathIcon,
  ArrowTrendingUpIcon,
  BanknotesIcon,
  BookOpenIcon,
  BookmarkIcon,
  BuildingOfficeIcon,
  CalculatorIcon,
  CheckCircleIcon,
  CodeBracketSquareIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  LinkIcon,
  QrCodeIcon,
  TicketIcon,
  UsersIcon,
  ViewfinderCircleIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { DashboardReport } from "./DashboardReport";


const DashboardComponent = (props: {
  retrieveInvoicesPaid?: number;
  retrieveAmountInvoices?: number;
  
}) => {
  const {
    retrieveInvoicesPaid,
    retrieveAmountInvoices,
  } = props;

  const navigate = useNavigate();

  const canDisplayLogs = ["ADMIN", "MANAGER", "CASHIER"];

  return (
    <>
      <div className="m-0 font-sans text-base antialiased font-normal leading-default bg-sky-50 text-slate-500">
        <div className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl transition-all duration-200">
          <div className="w-full py-0 mx-auto">
            <div className="flex flex-wrap max-md:mx-5 ">

              <DashboardReport
                className="m-3 max-md:m-2 max-md:w-full"
                rightIcon={
                  <TicketIcon className="relative w-6 h-6 text-xl text-white opacity-100 top-31/100" />
                }
                iconClasses="flex items-center justify-center w-10 h-10 text-center rounded-lg bg-gradient-to-tl from-sky-700 to-sky-500"
                title="Factures payées "
                value={retrieveInvoicesPaid}
              />

              <DashboardReport
                className="m-3 max-md:m-2 max-md:w-full"
                rightIcon={
                  <BanknotesIcon className="relative w-6 h-6 text-xl text-white opacity-100 top-31/100" />
                }
                iconClasses="flex items-center justify-center w-10 h-10 text-center rounded-lg bg-gradient-to-tl from-sky-700 to-sky-500"
                title="Montant Total "
                value={`${new Intl.NumberFormat().format(retrieveAmountInvoices as number)} fcfa`}
              />
            </div>
          </div>
        </div>
      </div>
     
    </>
  );
};

export default DashboardComponent;
