/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../../utils";

export interface PaginationFooterProps {
  skip: number;
  take: number;
  total: number;
  pageLimits?: number[];
  onPageChange?: (index: number) => void;
  onPageLimitChange?: (limit: number) => void;
}

export function PaginationFooter({
  skip,
  take,
  total,
  pageLimits = [100, 200, 300, 400],
  onPageChange,
  onPageLimitChange,
}: PaginationFooterProps) {
  const maxIndex = take > total ? total : skip + take;
  const numberOfPages =
    take === total ? Math.floor(total / take) : Math.floor(total / take) + 1;
  const currentPageIndex = skip / take;
  const goToPage = (pageIndex: number) => {
    if (onPageChange) onPageChange(pageIndex);
  };
  const changePageLimit = (pageLimit: number) => {
    if (onPageLimitChange) onPageLimitChange(pageLimit);
  };

  const pagesToDisplay = [];
  const MAX_PAGES_TO_SHOW = 4; // Nombre maximal de pages à afficher
  const PAGE_CHUNK_SIZE = 5; // Taille d'un "chunk" de pages
  const currentPageChunk = Math.floor(currentPageIndex / PAGE_CHUNK_SIZE); // Calcule le "chunk" actuel
  const lastPageChunk = Math.floor((numberOfPages - 1) / PAGE_CHUNK_SIZE); // Calcule le "chunk" de la dernière page

  // Calcule la plage de pages à afficher
  const startPage = Math.max(
    currentPageChunk * PAGE_CHUNK_SIZE,
    Math.min(
      Math.max(currentPageIndex - Math.floor(MAX_PAGES_TO_SHOW / 2), 0),
      numberOfPages - MAX_PAGES_TO_SHOW
    )
  );
  const endPage = Math.min(startPage + MAX_PAGES_TO_SHOW, numberOfPages);

  for (let i = startPage; i < endPage; i++) {
    pagesToDisplay.push(
      <a
        key={i}
        aria-current="page"
        onClick={() => goToPage(i)}
        className={classNames(
          currentPageIndex === i
            ? "z-10 bg-gray-50 dark:bg-slate-600 border-sky-500 text-sky-600"
            : "bg-white dark:bg-gray-800 border-gray-300 dark:border-slate-600 text-gray-500 hover:bg-gray-50 cursor-pointer",
          "relative inline-flex items-center px-2 py-1 border text-sm font-medium"
        )}
      >
        {i + 1}
      </a>
    );
  }
  if (currentPageChunk > 0) {
    pagesToDisplay.unshift(
      <span key="ellipsis-start" className="text-gray-500">
        ...
      </span>
    );
    pagesToDisplay.unshift(
      <a
        key={0}
        aria-current="page"
        onClick={() => goToPage(0)}
        className={classNames(
          currentPageIndex === 0
            ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
            : "bg-white dark:bg-gray-800 border-gray-300 text-gray-500 hover:bg-gray-50 cursor-pointer",
          "relative inline-flex items-center px-2 py-1 border text-sm font-medium"
        )}
      >
        1
      </a>
    );
  }
  if (currentPageChunk < lastPageChunk) {
    pagesToDisplay.push(
      <span key="ellipsis" className="text-gray-500">
        ...
      </span>
    );
    pagesToDisplay.push(
      <a
        key={numberOfPages - 1}
        aria-current="page"
        onClick={() => goToPage(numberOfPages - 1)}
        className={classNames(
          currentPageIndex === numberOfPages - 1
            ? "z-10 bg-indigo-50 border-emerald-500 text-emerald-600"
            : "bg-white dark:bg-gray-800 border-gray-300 text-gray-500 hover:bg-gray-50 cursor-pointer",
          "relative inline-flex items-center px-2 py-1 border text-sm font-medium"
        )}
      >
        {numberOfPages}
      </a>
    );
  }

  return (
    <div className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 sm:px-6">
      <div className="flex justify-between flex-1 sm:hidden">
        <a
          onClick={() => {
            currentPageIndex === 0 ? void 0 : goToPage(currentPageIndex - 1);
          }}
          className={classNames(
            currentPageIndex === 0
              ? "disabled cursor-not-allowed bg-gray-50"
              : "cursor-pointer bg-white dark:bg-gray-800 hover:bg-gray-50",
            "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 "
          )}
        >
          Précédent
        </a>
        <a
          onClick={() => {
            currentPageIndex === numberOfPages - 1
              ? void 0
              : goToPage(currentPageIndex + 1);
          }}
          className={classNames(
            currentPageIndex === numberOfPages - 1
              ? "disabled cursor-not-allowed bg-gray-50"
              : "cursor-pointer bg-white dark:bg-gray-800 hover:bg-gray-50",
            "ml-3 relative inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700"
          )}
        >
          Suivant
        </a>
      </div>
      <div className="hidden mr-8 md:flex-initial md:flex">
        <span className="relative z-0 inline-flex rounded-md shadow-sm">
          {pageLimits.map((pageLimit, index) => (
            <button
              key={index}
              type="button"
              onClick={() => changePageLimit(pageLimit)}
              className={classNames(
                index === 0 ? "rounded-l-md" : "",
                index === pageLimits.length - 1 ? "rounded-r-md" : "",
                pageLimit === take
                  ? "border-indigo-500 ring-indigo-500"
                  : "focus:border-indigo-500 focus:ring-indigo-500",
                "relative inline-flex items-center px-2 py-1  border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1"
              )}
            >
              {pageLimit}
            </button>
          ))}
        </span>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div className="">
          <p className="hidden text-sm text-gray-700 lg:inline-block">
            <span className="font-medium">
              Résultat {skip + 1} à {maxIndex > total ? total : maxIndex}{" "}
            </span>
            sur un total de <span className="font-medium">{total} </span>{" "}
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <a
              onClick={() => {
                currentPageIndex === 0
                  ? void 0
                  : goToPage(currentPageIndex - 1);
              }}
              className={classNames(
                currentPageIndex === 0
                  ? "disabled cursor-not-allowed bg-gray-50"
                  : "cursor-pointer bg-white hover:bg-gray-50",
                "relative inline-flex items-center px-2 py-1 rounded-l-md border border-gray-300  text-sm font-medium text-gray-500 "
              )}
            >
              <span className="sr-only">Suivant</span>
              <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
            </a>
            {pagesToDisplay}
            <a
              onClick={() => {
                currentPageIndex === numberOfPages - 1
                  ? void 0
                  : goToPage(currentPageIndex + 1);
              }}
              className={classNames(
                currentPageIndex === numberOfPages - 1
                  ? "disabled cursor-not-allowed bg-gray-50"
                  : "cursor-pointer bg-white hover:bg-gray-50",
                "relative inline-flex items-center px-2 py-1 rounded-r-md border border-gray-300  text-sm font-medium text-gray-500 "
              )}
            >
              <span className="sr-only">Suivant</span>
              <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default PaginationFooter;
