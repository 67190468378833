import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { classNames } from '../../utils';

type ModalProps = {
  modalTitle?: string;
  isOpen: boolean;
  closeModal: () => any;
  maxSize?: 'md' | 'lg' | 'xl' | '2xl' | '3xl' | 'sm';
  children: any
};

const Modal: React.FC<ModalProps> = ({
  modalTitle,
  isOpen,
  closeModal,
  maxSize = 'md',
  children,
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed inset-0 max-w-580-px  z-10 m-auto overflow-y-auto`}
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Panel className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={classNames(
                maxSize ? `sm:max-w-${maxSize}` : '',
                'inline-block w-full p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg 2xl:rounded-2xl'
              )}
            >
              <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                <button
                  type="button"
                  className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  onClick={closeModal}
                >
                  <span className="sr-only">Close</span>
                  <XCircleIcon className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              {modalTitle && (
                <Dialog.Title
                  as="h3"
                  className={classNames(
                    ['xl', '2xl'].includes(maxSize) ? `text-lg` : 'text-base',
                    'text-base font-medium leading-6 text-center w-5/6 text-gray-700'
                  )}
                >
                  {modalTitle}
                </Dialog.Title>
              )}
              <div className="my-4">{children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
