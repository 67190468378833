export const usersCollectionName = "sankmoney_users";
export const membershipCollectionName = "sankmoney_membership";
export const gasStationMembershipCollectionName =
  "sankmoney_gasStation_membership";
export const companiesMembershipCollectionName =
  "sankmoney_companies_membership";
export const accountsCollectionName = "sankmoney_accounts";
export const userContactsCollectionName = "sankmoney_users_contacts";
export const transactionCollectionName = "sankmoney_transactions";
export const gasStationCollectionName = "sankmoney_gas_stations";
export const companyCollectionName = "sankmoney_companies";
export const companyReportCollectionsName = "sankmoney_companies_transactions_report";
export const companiesAttendantAccountCollectionsName =
  "sankmoney_companies_attendant_accounts";
export const gasStationOrderCollectionName = "sankmoney_gas_station_order";
export const gasStationsAttendantAccountCollectionsName =
  "sankmoney_gas_station_attendant_accounts";
export const agencyCollectionsName = "sankmoney_agencies";
export const feesCollectionsName = "sankmoney_fees";
export const commissionsCollectionsName = "sankmoney_commissions";
export const globalConfigCollectionsName = "sankmoney_global_configs";
export const auditLogCollectionName = "sankmoney_audit_log";
export const notificationsCollectionName = "sankmoney_notifications";
export const contactsCollectionName = "sankmoney_user_contacts";
export const onboardingDataCollectionCollectionsName =
  "sankmoney_onboarding_data_collection";
export const sankmoneyFeesAccount = "sankmoney_fees_account";
export const sankmoneyRefillsAwaitingValidation =
  "sankmoney_refills_awaiting_validation";
export const sankmoneyDealerAccountRefillsAwaitingValidation =
  "sankmoney_dealer_account_refills_awaiting_validation";
export const pendingTransactionsCollectionsName =
  "sankmoney_pending_transactions";
export const generatedCodeAgentCollectionsName =
  "sankmoney_generated_code_agent";
export const generatedCodeMerchantCollectionsName =
  "sankmoney_generated_code_merchant";
export const generatedCodeGasStationCollectionsName =
  "sankmoney_generated_code_gasStation";
export const commissionsPaidCollectionsName = "sankmoney_commissions_paid";
export const blockedTransactionCollectionName =
  "sankmoney_blocked_transactions";
export const updateOrDeleteauditLogCollectionName =
  "sankmoney_update_or_delete_audit_log";
export const usersAffiliatedToAgent = "sankmoney_users_affiliated_to_agent";
export const cardsCollectionName = "sankmoney_cards";
  export const userCardRelationCollection = "sankmoney_user_card_relation";
export const referralsCollectionName = "sankmoney_referrals";
export const superMerchantAndMerchantsForeingKeysCollectionsName = "sankmoney_supermerchants_and_merchants_foreing_keys"
export const companyContactsCollectionName = "company_contacts"
export const companyContactCollectionsName = "sankmoney_companies_contacts";
export const snblUsersInvoicesCollectionName = "sankmoney_snbl_users_unpaid_invoices";

export const cardsConfigs = "cards_configs";
export const defaultAmount = "100";
export const alertesConfigId = "alerts";
export const PhoneNumberDefaultLength = 8;
export const cardLength = 13;
export const feesAccountId = "fees_account";
export const withdrawCodeLength = 5;
export const feesConfigId = "fees";
export const agentCreateUsersParams = "agent_create_users_params";
export const defaultUserAccountCapAmount = 2000000;
export const numberOfCompanyBulkPaiementFileData = 100;


export const retrieveAllInvoicesUrl = "https://europe-west1-sank-refonte.cloudfunctions.net/sankMoneyFactureONEA-retrieveAllPaidInvoices";
