import { Route, Routes, Navigate } from "react-router-dom";
import LoginWithEmail from "./components/Auth/LoginWithEmail";
import { OneaDashoard } from "./pages/OneaDashboard";
import useFindUser from "./hooks/useFindUser";
import { UserContext } from "./contexts";
import ManageOneaInvoicesPage from "./pages/Factures/Onea/ManageOneaInvoicesPage";
import Dashboard from "./pages/Dashboard";
import { UserRole } from "./interfaces";
import Moment from "react-moment";
import "moment/locale/fr";
import CustomToaster from "./components/CustomToaster";

function App() {
  Moment.globalLocale = "fr";

  const { user, setUser } = useFindUser();
  return (
    <div className="h-full App">
      <UserContext.Provider
        value={{
          user: user,
          setUser: setUser,
        }}
      >
        <Routes>
          <Route path="/login" element={<LoginWithEmail />}></Route>
          <Route
            path="/"
            element={
              user && user.userRole === UserRole.ONEAAGENT ? (
                <OneaDashoard />
              ) : (
                <LoginWithEmail />
              )
            }
          >
            <Route path="/" element={<Dashboard />}></Route>
            <Route
              path="onea/invoices"
              element={<ManageOneaInvoicesPage />}
            ></Route>
          </Route>
          <Route path="*" element={<OneaDashoard />}></Route>
        </Routes>
      </UserContext.Provider>
      <CustomToaster />
    </div>
  );
}

export default App;
