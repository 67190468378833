/* eslint-disable jsx-a11y/anchor-is-valid */
import { Menu, Transition } from '@headlessui/react';
import { Fragment, MouseEventHandler } from 'react';
import { PencilIcon, DocumentDuplicateIcon, TrashIcon, Bars3Icon } from '@heroicons/react/24/outline';
import { useConfirm } from '../../../hooks/useConfirm';
import { classNames } from '../../../utils';

export interface TableEditMenuProps {
  rowEditHandler?: MouseEventHandler;
  rowDuplicateHandler?: MouseEventHandler;
  rowDeleteHandler?: MouseEventHandler;
}

export function TableRowEditMenu(props: TableEditMenuProps) {
  const { isConfirmModal } = useConfirm();
  
  
  const onDelete = async (event: any) => {
    const isConfirm = await isConfirmModal("Opération irréversible", "Êtes vous sûr de vouloir supprimer cette données", "Oui supprimer", true);
    
    if (isConfirm && props.rowDeleteHandler)
    props.rowDeleteHandler(event);
  }
  return (
    <Menu as="div" className="relative flex items-center justify-end">
      <Menu.Button className="inline-flex items-center justify-center w-8 h-8 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
        <span className="sr-only">Action</span>
        <Bars3Icon className="w-5 h-5" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute top-0 z-10 w-48 mx-3 mt-1 origin-top-right bg-white divide-y divide-gray-200 rounded-md shadow-lg right-7 ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {props.rowEditHandler && (
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={props.rowEditHandler}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm cursor-pointer'
                    )}
                  >
                    <PencilIcon
                      className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Edit
                  </a>
                )}
              </Menu.Item>
            )}
            {props.rowDuplicateHandler && (
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={props.rowDuplicateHandler}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm cursor-pointer'
                    )}
                  >
                    <DocumentDuplicateIcon
                      className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Duplicate
                  </a>
                )}
              </Menu.Item>
            )}
          </div>
          <div className="py-1">
            {props.rowDeleteHandler && (
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={(event) => onDelete(event)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm cursor-pointer'
                    )}
                  >
                    <TrashIcon
                      className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Delete
                  </a>
                )}
              </Menu.Item>
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default TableRowEditMenu;
