/* eslint-disable @typescript-eslint/no-explicit-any */
import TableRowEditMenu from "./TableRowEditMenu";
import { useState } from "react";
import { ArrowUpIcon, ArrowDownIcon } from "@heroicons/react/24/outline";
import Fetch from "../Fetch";
import { PaginationFooter } from "../PaginationFooter";
import { classNames, unwindObject } from "../../../utils";

export type IRow = Record<string, any>;

export class PaginationDto {
  total = 0;

  limit = 400;

  offset = 0;

  totalAmount? = 0;
}

export class PaginatedDto<TData> {
  pagination: PaginationDto = new PaginationDto();

  data: TData[] = [];
}

export function paginate<T>(
  take: number,
  skip: number,
  data: T[],
  total: number,
  totalAmount?: number
): PaginatedDto<T> {
  return {
    pagination: {
      limit: take,
      offset: skip,
      total,
      totalAmount
    },
    data,
  };
}
export interface IColumn {
  field: string; //This field can have a full stop(.) will allow us to read further into objects
  label?: string; //This will be used to display in the table heading
  useLabel?: string;
  sortField?: string;
  sortDirection?: "asc" | "desc";
  sortDisable?: boolean;

  //Indicates of this guy should be used to search
  useInSearch?: boolean;

  //Indicates If this should be used in displaying data
  useInDisplay?: boolean;

  useInExport?: boolean;
}
/**
 * A function whose return value is either a JSX element or a string
 */
export type RowRenderFunction = (
  row: IRow,
  col: IColumn,
  value: unknown,
  index: number
) => JSX.Element | string;

export type RowActionHandler = (id: any, row: any) => void;

export type GetItemValue = (id: any, column: any) => string;
export interface TableProps {
  url: string;
  columns: IColumn[];
  paginate?: Omit<PaginationDto, "total"> & { sort: string };
  noContentText?: string;
  rowRender?: RowRenderFunction;
  loadingSkeletonCount?: number;
  refreshHandler?: (refreshData: () => void) => void;
  rowEditHandler?: RowActionHandler;
  rowClickHandler?: RowActionHandler;
  rowDuplicateHandler?: RowActionHandler;
  rowDeleteHandler?: RowActionHandler;
  getItemValue?: GetItemValue;
  fetchDataMapper?: (data: any) => PaginatedDto<IRow>;
  condensed?: boolean;
  currentUserId?: string;
  itemcreatedAfter?: string;
  itemcreatedBefore?: string;
  metterNumber?: string;
  retrieveCurrentPeriodTotalAmount?: (amount: number) => void;
  retrieveCurrentPeriodTotalInvoices?: (invoices: number) => void;
  retrieveCurrentPeriodInvoices?: (invoices: any[]) => void;
}

export function Table({
  condensed = false,
  fetchDataMapper = (data) => data,
  ...props
}: TableProps) {
  const shouldAddEditColumn = props.rowEditHandler || props.rowDeleteHandler;
  const [paginate, setPaginate] = useState<
    Omit<PaginationDto, "total"> & { sort: string }
  >(props.paginate || { offset: 0, limit: 10, sort: "id:asc" });
  const buildUrl = (url: string) => {
    const where = {
      subscribernumber: props.metterNumber,
    };
    if (paginate) {
      return `${url}?skip=${paginate.offset}&take=${
        paginate.limit
      }&orderBy=${paginate.sort.replace(":", "%3A")}${
        props.currentUserId ? `&currentUserId=${props.currentUserId}` : ""
      }${props.itemcreatedAfter ? `&startDate=${props.itemcreatedAfter}` : ""}${
        props.itemcreatedBefore ? `&endDate=${props.itemcreatedBefore}` : ""
      }${props.metterNumber ? `&where=${JSON.stringify(where)}` : ""}`;
    } else return url;
  };

  const sortByColumn = (column: IColumn) => {
    if (!column.sortDisable) {
      let direction = "asc";
      if (paginate.sort.split(":")[0] === (column.sortField || column.field)) {
        direction = paginate.sort.split(":")[1] === "asc" ? "desc" : "asc";
      }
      setPaginate({
        offset: paginate.offset,
        limit: paginate.limit,
        sort: `${column.sortField || column.field}:${direction}`,
      });
    }
  };
  return (
    <Fetch
      url={buildUrl(props.url)}
      loadingSkeletonCount={props.loadingSkeletonCount || 5}
      refreshHandler={props.refreshHandler}
      fetchDataMapper={fetchDataMapper}
      retrieveCurrentPeriodTotalAmount={props.retrieveCurrentPeriodTotalAmount}
      retrieveCurrentPeriodTotalInvoices={props.retrieveCurrentPeriodTotalInvoices}
      retrieveCurrentPeriodInvoices={props.retrieveCurrentPeriodInvoices}
      renderData={(page: PaginatedDto<IRow>) => {
        return (
          <>
            <div className="overflow-x-auto border-gray-200 dark:border-gray-700 pb-14">
              <table className="w-full border-collapse table-auto">
                <thead className="bg-gray-50">
                  {page && page.data && page.data.length > 0 && (
                    <tr>
                      {props.columns.map((column: IColumn, colIndex) => {
                        return (
                          <th
                            key={colIndex.toString()}
                            scope="col"
                            onClick={() => sortByColumn(column)}
                            className={classNames(
                              paginate.sort.split(":")[0] ===
                                (column.sortField || column.field)
                                ? "font-bold text-gray-800"
                                : "font-semibold text-gray-500",
                              column.sortDisable ? "" : "cursor-pointer",
                              "px-6 py-3 text-left text-xs uppercase tracking-wider"
                            )}
                          >
                            <div className="flex justify-start">
                              {paginate.sort.split(":")[0] ===
                                (column.sortField || column.field) &&
                                paginate.sort.split(":")[1] === "asc" && (
                                  <ArrowUpIcon
                                    className="w-5 h-5 mr-1 text-gray-700"
                                    aria-hidden="true"
                                  />
                                )}
                              {paginate.sort.split(":")[0] ===
                                (column.sortField || column.field) &&
                                paginate.sort.split(":")[1] === "desc" && (
                                  <ArrowDownIcon
                                    className="w-5 h-5 mr-1 text-gray-700"
                                    aria-hidden="true"
                                  />
                                )}

                              <span>
                                {column.useLabel !== undefined
                                  ? column.useLabel
                                  : column.label
                                  ? column.label
                                  : column.field}
                              </span>
                            </div>
                          </th>
                        );
                      })}
                      {shouldAddEditColumn && (
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-gray-500 uppercase"
                        ></th>
                      )}
                    </tr>
                  )}
                </thead>
                <tbody>
                  {page && page.data && page.data.length > 0 ? (
                    page.data.map((row: IRow, rowIndex) => (
                      <tr
                        key={rowIndex.toString()}
                        // Stripped
                        //className={rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                        onClick={() => {
                          props.rowClickHandler
                            ? props.rowClickHandler(rowIndex, row)
                            : void 0;
                        }}
                        className={classNames(
                          props.rowClickHandler ? "cursor-pointer" : "",
                          "hover:bg-gray-200"
                        )}
                      >
                        {props.columns.map((column: IColumn, columnIndex) => (
                          <td
                            key={columnIndex.toString()}
                            className={classNames(
                              condensed ? "px-4 py-2" : "px-6 py-4",
                              "whitespace-nowrap text-sm font-medium text-gray-700 bg-white"
                            )}
                          >
                            {props.rowRender
                              ? props.rowRender(
                                  row,
                                  column,
                                  unwindObject(row, column.field),
                                  columnIndex
                                )
                              : unwindObject(row, column.field)}
                          </td>
                        ))}
                        {shouldAddEditColumn && (
                          <td className="px-6 py-4 text-sm font-medium text-gray-700 whitespace-nowrap dark:text-gray-300">
                            <TableRowEditMenu
                              rowDeleteHandler={(e) => {
                                props.rowDeleteHandler
                                  ? props.rowDeleteHandler(rowIndex, row)
                                  : void 0;
                              }}
                              rowEditHandler={(e) =>
                                props.rowEditHandler
                                  ? props.rowEditHandler(rowIndex, row)
                                  : void 0
                              }
                            />
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr className="border-b border-gray-200 hover:bg-table-col bg-table-col dark:border-gray-700 ">
                      <td
                        className="px-4 py-4 text-center"
                        colSpan={
                          props.columns.length + (shouldAddEditColumn ? 1 : 0)
                        }
                      >
                        <div className="p-4 m-2 text-center rounded bg-gray-50 dark:bg-gray-700">
                          <h3 className="mt-2 text-sm font-medium text-gray-600 dark:text-gray-300">
                            {props.noContentText || `Pas de contenu disponible`}
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            La requête ne renvoie pas de donnée.
                          </p>
                          <div className="inline-flex justify-center w-full px-4 py-2 mt-6">
                            <svg className="w-40 h-40" viewBox="0 0 650 650">
                              <path
                                d="M687.3279,276.08691H512.81813a15.01828,15.01828,0,0,0-15,15v387.85l-2,.61005-42.81006,13.11a8.00676,8.00676,0,0,1-9.98974-5.31L315.678,271.39691a8.00313,8.00313,0,0,1,5.31006-9.99l65.97022-20.2,191.25-58.54,65.96972-20.2a7.98927,7.98927,0,0,1,9.99024,5.3l32.5498,106.32Z"
                                transform="translate(-276.18187 -133.91309)"
                                fill="#f2f2f2"
                              />
                              <path
                                d="M725.408,274.08691l-39.23-128.14a16.99368,16.99368,0,0,0-21.23-11.28l-92.75,28.39L380.95827,221.60693l-92.75,28.4a17.0152,17.0152,0,0,0-11.28028,21.23l134.08008,437.93a17.02661,17.02661,0,0,0,16.26026,12.03,16.78926,16.78926,0,0,0,4.96972-.75l63.58008-19.46,2-.62v-2.09l-2,.61-64.16992,19.65a15.01489,15.01489,0,0,1-18.73-9.95l-134.06983-437.94a14.97935,14.97935,0,0,1,9.94971-18.73l92.75-28.4,191.24024-58.54,92.75-28.4a15.15551,15.15551,0,0,1,4.40966-.66,15.01461,15.01461,0,0,1,14.32032,10.61l39.0498,127.56.62012,2h2.08008Z"
                                transform="translate(-276.18187 -133.91309)"
                                fill="#3f3d56"
                              />
                              <path
                                d="M398.86279,261.73389a9.0157,9.0157,0,0,1-8.61133-6.3667l-12.88037-42.07178a8.99884,8.99884,0,0,1,5.9712-11.24023l175.939-53.86377a9.00867,9.00867,0,0,1,11.24072,5.9707l12.88037,42.07227a9.01029,9.01029,0,0,1-5.9707,11.24072L401.49219,261.33887A8.976,8.976,0,0,1,398.86279,261.73389Z"
                                transform="translate(-276.18187 -133.91309)"
                                fill="#6c63ff"
                              />
                              <circle
                                cx="190.15351"
                                cy="24.95465"
                                r="20"
                                fill="#6c63ff"
                              />
                              <circle
                                cx="190.15351"
                                cy="24.95465"
                                r="12.66462"
                                fill="#fff"
                              />
                              <path
                                d="M878.81836,716.08691h-338a8.50981,8.50981,0,0,1-8.5-8.5v-405a8.50951,8.50951,0,0,1,8.5-8.5h338a8.50982,8.50982,0,0,1,8.5,8.5v405A8.51013,8.51013,0,0,1,878.81836,716.08691Z"
                                transform="translate(-276.18187 -133.91309)"
                                fill="#e6e6e6"
                              />
                              <path
                                d="M723.31813,274.08691h-210.5a17.02411,17.02411,0,0,0-17,17v407.8l2-.61v-407.19a15.01828,15.01828,0,0,1,15-15H723.93825Zm183.5,0h-394a17.02411,17.02411,0,0,0-17,17v458a17.0241,17.0241,0,0,0,17,17h394a17.0241,17.0241,0,0,0,17-17v-458A17.02411,17.02411,0,0,0,906.81813,274.08691Zm15,475a15.01828,15.01828,0,0,1-15,15h-394a15.01828,15.01828,0,0,1-15-15v-458a15.01828,15.01828,0,0,1,15-15h394a15.01828,15.01828,0,0,1,15,15Z"
                                transform="translate(-276.18187 -133.91309)"
                                fill="#3f3d56"
                              />
                              <path
                                d="M801.81836,318.08691h-184a9.01015,9.01015,0,0,1-9-9v-44a9.01016,9.01016,0,0,1,9-9h184a9.01016,9.01016,0,0,1,9,9v44A9.01015,9.01015,0,0,1,801.81836,318.08691Z"
                                transform="translate(-276.18187 -133.91309)"
                                fill="#6c63ff"
                              />
                              <circle
                                cx="433.63626"
                                cy="105.17383"
                                r="20"
                                fill="#6c63ff"
                              />
                              <circle
                                cx="433.63626"
                                cy="105.17383"
                                r="12.18187"
                                fill="#fff"
                              />
                            </svg>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {page && page.data && page.data.length > 0 && (
              <PaginationFooter
                skip={page.pagination.offset}
                take={page.pagination.limit}
                total={page.pagination.total}
                onPageChange={(pageIndex: number) => {
                  setPaginate({
                    offset: pageIndex * paginate.limit,
                    limit: paginate.limit,
                    sort: paginate.sort,
                  });
                }}
                onPageLimitChange={(pageLimit: number) => {
                  setPaginate({
                    offset: paginate.offset,
                    limit: pageLimit,
                    sort: paginate.sort,
                  });
                }}
              />
            )}
          </>
        );
      }}
      renderLoading={(loading, loadingSkeletonCount) =>
        loading && (
          <div className="p-4 animate-pulse">
            <div className="h-4 mt-3 mb-6 bg-gray-400 rounded"></div>
            {[...Array(loadingSkeletonCount || 5)].map((e, i) => (
              <div key={i}>
                <div className="h-4 mb-6 bg-gray-200 rounded"></div>
                <div className="h-4 mb-6 bg-gray-300 rounded"></div>
              </div>
            ))}
          </div>
        )
      }
    />
  );
}

export default Table;
