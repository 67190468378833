import { Fragment, useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { classNames } from "../../utils";
import { Dialog, Menu, Transition } from "@headlessui/react";
import SankLogoTransparent from "../../ONEA.png";
import firebase from "firebase/compat/app";
import { auth, db } from "../../firebase";
import {
  Bars3BottomLeftIcon,
  XMarkIcon,
  TicketIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import { accountsCollectionName, usersCollectionName } from "../../config";
import { UserInterface, UserRole } from "../../interfaces";
import LoginWithEmail from "../../components/Auth/LoginWithEmail";
import toast from "react-hot-toast";

const oneaNavigationMenu = [
  {
    name: "Accueil",
    path: "/",
    icon: HomeIcon,
  },
  {
    name: "Factures",
    path: "onea/invoices",
    icon: TicketIcon,
  },
];

export const OneaDashoard = () => {
  const [isSignedIn, setIsSignedIn] = useState(auth.currentUser != null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);

  const updateUserState = async (userId: string) => {
    const userRef = db.collection(usersCollectionName).doc(userId);
    const user = await userRef.get();
    const userData = user.data();
    if (user.exists) {
      if (!userData?.active) {
        firebase.auth().signOut();
        return navigate("/login");
      }
      if (userData.userRole !== UserRole.ONEAAGENT) {
        firebase.auth().signOut();
        toast.error(
          "Vous n'êtes pas authorisé à accédé à cette console (401)."
        );
        return navigate("/login");
      }
      setUser(userData as UserInterface);
    } else {
      firebase.auth().signOut();
      return navigate("/login");
    }
  };

  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((currentUser) => {
        if (currentUser?.uid) {
          updateUserState(currentUser?.uid as string);
          setIsSignedIn(!!currentUser);
        }
      });
    return () => unregisterAuthObserver();
  }, []);

  if (!isSignedIn) {
    return (
      <div className="flex flex-col justify-center py-6 sm:px-6 lg:px-8">
        <LoginWithEmail />
      </div>
    );
  }

  return (
    <>
      <div className="flex h-full">
        <div className="hidden overflow-y-auto bg-sky-600 w-44 lg:block">
          <div className="flex flex-col items-center w-full py-6">
            <div className="flex items-center flex-shrink-0">
              <span>
                <img
                  className="w-auto h-16"
                  src={SankLogoTransparent}
                  alt="Sank"
                />
              </span>
            </div>
            <div className="flex-1 w-full px-2 mt-6 space-y-2">
              {(() => {
                let navigationMenu = oneaNavigationMenu;

                return (
                  <>
                    {navigationMenu?.map((item, index) => (
                      <NavLink
                        key={index}
                        to={item.path}
                        className={({ isActive }) =>
                          classNames(
                            isActive
                              ? "bg-white text-black"
                              : "text-white hover:bg-white hover:text-black",
                            "group w-full px-4 py-2 my-4 rounded-md flex flex-row items-center justify-start text-sm font-medium bo  rder-t border-gray-200 dark:border-gray-700"
                          )
                        }
                      >
                        {({ isActive }) => (
                          <>
                            <item.icon
                              className={classNames(
                                isActive
                                  ? "text-black"
                                  : "text-white group-hover:text-black ",
                                "h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            <span className="ml-2">{item.name}</span>
                          </>
                        )}
                      </NavLink>
                    ))}
                  </>
                );
              })()}
            </div>
          </div>
        </div>
        <Transition.Root show={mobileMenuOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-20"
            onClose={setMobileMenuOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-sky-600">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute right-0 p-1 top-1 -mr-14">
                      <button
                        type="button"
                        className="flex items-center justify-center w-12 h-12 rounded-full focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <XMarkIcon
                          className="w-6 h-6 text-white"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Fermer</span>
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex items-center justify-center flex-shrink-0 px-4">
                    <span>
                      <img
                        className="w-auto h-16"
                        src={SankLogoTransparent}
                        alt="Sank"
                      />
                    </span>
                  </div>
                  <div className="flex-1 h-0 px-2 mt-5 overflow-y-auto">
                    <nav className="flex flex-col h-full space-y-1">
                      {(() => {
                        let navigationMenu = oneaNavigationMenu;

                        return (
                          <>
                            {navigationMenu?.map((item, index) => (
                              <NavLink
                                key={index}
                                onClick={() => setMobileMenuOpen(false)}
                                to={item.path}
                                className={({ isActive }) =>
                                  classNames(
                                    isActive
                                      ? "bg-white text-black"
                                      : "text-white hover:bg-white hover:text-black",
                                    "group w-full px-4 py-2 my-1 rounded-md flex flex-row items-center justify-start text-sm font-medium bo  rder-t border-gray-200 dark:border-gray-700"
                                  )
                                }
                              >
                                {({ isActive }) => (
                                  <>
                                    <item.icon
                                      className={classNames(
                                        isActive
                                          ? "text-black"
                                          : "text-white group-hover:text-black ",
                                        "h-6 w-6"
                                      )}
                                      aria-hidden="true"
                                    />
                                    <span className="ml-2">{item.name}</span>
                                  </>
                                )}
                              </NavLink>
                            ))}
                          </>
                        );
                      })()}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <div className="flex flex-col flex-1 overflow-hidden">
          <header className="w-full">
            <div className="relative z-10 flex flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm">
              <button
                type="button"
                className="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500 lg:hidden"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3BottomLeftIcon className="w-6 h-6" aria-hidden="true" />
              </button>
              <div className="flex justify-end flex-1 px-4 sm:px-6">
                <div className="flex items-center my-auto text-sm font-medium max-md:hidden"></div>
                <div className="items-center hidden my-auto text-sm font-medium md:flex">
                  <div>
                    <span className="text-gray-500">Connecté en tant que </span>
                    <span className="p-1 text-blue-600 border border-spacing-1">
                      {user?.displayName}
                    </span>
                  </div>
                  <Menu as="div" className="relative flex-shrink-0">
                    <div>
                      <Menu.Button className="flex text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="w-8 h-8 p-1 border rounded-full"
                          src={
                            user?.profilImageUrl
                              ? user?.profilImageUrl
                              : `https://ui-avatars.com/api/?background=random&name=${
                                  user?.displayName || "S"
                                }`
                          }
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-20 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-4 py-3">
                          <p className="text-sm">Connecté en tant que</p>
                          <p className="text-sm font-medium text-red-600 truncate">
                            {firebase.auth().currentUser?.email}
                          </p>
                        </div>
                        {/* {userNavigation.map((item, index) => (
                            <div key={index}>
                                <hr className="h-px bg-gray-200 border-0 dark:bg-gray-200" />
                                <Menu.Item>
                                {({ active }) => (
                                    <NavLink
                                    to={item.path}
                                    className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                    >
                                    {item.name}
                                    </NavLink>
                                )}
                                </Menu.Item>
                            </div>
                            ))} */}
                        {
                          <Menu.Item>
                            {({ active }) => (
                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                              <>
                                <hr className="h-px bg-gray-200 border-0 dark:bg-gray-200" />
                                <div
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                  )}
                                  onClick={() => {
                                    firebase.auth().signOut();
                                    navigate("/login");
                                  }}
                                >
                                  Se déconnecter
                                </div>
                              </>
                            )}
                          </Menu.Item>
                        }
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </header>

          {/* Main content */}
          <div className="h-full p-1 overflow-y-auto bg-white shadow md:m-2 md:rounded-lg lg:m-4">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
